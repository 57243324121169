@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap");
@import "../src/assets/css/variables";
@import "~bootstrap/scss/bootstrap";
@import "../src/assets/css/utility";
@import "../src/assets/css/general";


html {scrollbar-color:#0D51A2}

html,
body {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Open Sans";
  background-color: $white;
  color: black;
  overflow-x: hidden;
  overflow-x: hidden;
  scrollbar-color:  #0D51A2 ;

  &::-webkit-scrollbar-track
  {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background: rgba(105, 105, 105, 0);
      border-radius: 14px;
  }
  
  &::-webkit-scrollbar
  {
      width: 12px;
      background: rgba(105, 105, 105, 0);
      border-radius: 14px;
  }
  
  &::-webkit-scrollbar-thumb
  {
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background: linear-gradient(180deg, #25BFEB 0%, #0D51A2 100%);
      border-radius: 14px;
  }
}
.main {
  padding: 0px 50px;
  display: block;
}

.btn-bg{
  background: linear-gradient(180deg, #3246D3 0%, #5846DF 100%);
  border-radius: 8px;
}

.custom-btn {
  color: $white;
  border-radius: 8px;
  width: 168px;
  height: 48px;
  border: 0;
  outline: none !important;
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  &:hover , &:focus , &:active{
    color: $white;
  }
}

.btn:focus {
  border: 0;
  outline: none !important;
  box-shadow: none;
  color: $white;
}
