$enable-responsive-font-size: true;
$primary-font-family: "Open Sans";
$headings-font-family: $primary-font-family;
$font-family-base: $primary-font-family;

//Basic Color
$skyblue: #0066ff33;
$primary: #0066ff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

// Theme Colors
$themeBg: #fafbff; // Theme BG Color
$white: #ffffff;
$black: #000000;
$yellow: #fecd00;
$grey: #c1c1c1;
$toryBlue: #0d51a2;
$footerBg: #f9faff;
$headingColor: #484848;
$darkGrey : #909090;
//#484848 // term-condition and privacy-policy title color
//#F9FAFF;

// $link-color: $activeColor;
// $link-hover-color: $activeColor;

// $primary: $activeColor;
// $success: $green !default;
// $info: $cyan !default;
$warning: $yellow !default;
// $danger: $red !default;
// $light: $gray-100 !default;
// $dark: $gray-900 !default;
// #787878

$theme-colors: (
  "theme": $themeBg,
  "white": $white,
  "black": $black,
  "warning": $warning,
  "primary": $toryBlue,
  "darkGrey" : $darkGrey
);

$grid-columns: 12;
$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px,
//   xxl: 1400px,
// );
