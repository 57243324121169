@import "./variables";

h1,
h2,
h3,
h5,
h6 {
  color: $headingColor;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 !important;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    padding: 0px 0px;
    color: $toryBlue;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    margin-top: 25px;
  }
}
.nav-link {
  color: $toryBlue;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  &:focus{
    color: #0052cc;
  }
}

.navbar-light > .navbar-nav > .nav-link {
  color: $toryBlue;
}

.navbar-toggler {
  &:focus {
    box-shadow: none;
  }
}

.logo-nevly {
  width: 162px;
  height: 46px;
}

.accordion-item {
  border: none;
  border-left: none;
  border-right: none;
  border-radius: 0 !important;
  border-bottom: 1px solid #8896a6;
  margin: 15px 0px;
  padding: 20px 0px;
}

.accordion-body {
  padding: 10px 0px;
  font-size: 18px;
  font-weight: 400;
}

.accordion-button{
  button {
    @extend .fs-20, .fw-600;
    .arrow{
      line-height: 15px;
    }
  }
}
.custom-header::after {
  content: none;
}
width-18{
  width:18%;
}  

.active-link-nav{
  border-bottom: 1px solid #FECD00;
}

@media screen and (max-width:992px) {
  .width-18{
    width:100%;
  } 
}
  



@media screen and (max-width: 768px) {
  .active-link-nav{
    border-bottom: none;
    color:#FECD00 !important;
  }
  .logo-nevly {
    width: 95px !important;
    height: 25px !important;
  }
  .w-md-100 {
    width: 96% !important;
  }
  .ml-40 {
    margin-left: 20px !important;
  }
  header{
    position: fixed;
    background: white;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 999;
    left: 0;
    top:0;
  }
  .navbar-nav {
    height: calc(100vh - 110px) !important;
    margin-top: 60px !important;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-inline-start: 0;

    .banner-app {
      display: flex;
      align-items: center;
       width: 100vw;
      height: 140px;
      background: linear-gradient(270deg, #fecd00 0%, #1de1e1 100%);
      backdrop-filter: blur(40px);
      bottom: 14px;
      left: calc(100vw - 102vw);
      position: absolute;
      z-index: 1;
    }
    .nav-item {
      padding: 0 !important;
      margin: 0;
      margin-bottom: 45px;
      display: flex;
      align-items: center;
      .nav-link {
        padding: 0;
        color: $toryBlue;
        font-weight: 600;
        display: flex;
        .about-icon {
          width: 20px;
          height: 20px;
          background-image: url("../img/home.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          display: inline-block;
          margin-right: 25px;

          &--active {
            background-image: url("../img/home-active.svg");
          }
        }
        .service-icon {
          width: 20px;
          height: 20px;
          background-image: url("../img/service.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          display: inline-block;
          margin-right: 25px;

          &--active {
            background-image: url("../img/service-active.svg");
          }
        }
        .pricing-icon {
          width: 20px;
          height: 20px;
          background-image: url("../img/pricing.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          display: inline-block;
          margin-right: 25px;

          &--active {
            background-image: url("../img/pricing-active.svg");
          }
        }

        .reviews-icon {
          width: 20px;
          height: 20px;
          background-image: url("../img/review.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          display: inline-block;
          margin-right: 25px;

          &--active {
            background-image: url("../img/review-active.svg");
          }
        }

        .faq-icon {
          width: 20px;
          height: 20px;
          background-image: url("../img/faq.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          display: inline-block;
          margin-right: 25px;

          &--active {
            background-image: url("../img/faq-active.svg");
          }
        }
      }
    }
  }
}



.accordion-item{
  .accordion-header div button{
    color:black !important;  
  } 
  .accordion-body{
    color: black !important;
  }
  .accordion-button button div { color:black !important }
}

.accordion-tab-custom{
  .nav-item{
    .active{
      color: black !important;
      font-weight: bold;
    }
    .border-bottom{ border-bottom: 2px solid black !important }
  }
  .grey{
    color:black !important
  }
}

.display-1{
  margin-top:125px !important;
  font-family: Open Sans  !important;
  color: black;
  font-weight: bold;
  font-size: 64px !important;
}